<template>
  <div class="shadown_container" @click="isShadow = false" v-show="isShadow">
    <div class="shadow_body" ref="shadow_body" :style="styles">
      <van-cell-group>
        <van-cell
          v-for="(item, index) of tag"
          :key="index"
          :title="item.title"
          :icon="item.icon"
          @click="selectTag(item.type, result)"
        />
      </van-cell-group>
    </div>
  </div>
</template>
<script>
import { caseBase } from '~api-config'
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  props: {
    position: {
      type: Object,
      default: () => {
        return {}
      }
    },
    result: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isShadow: false,
      tag: [],
      styles: {}
    }
  },
  watch: {
    position(obj) {
      this.isShadow = true
      this.$nextTick(() => {
        const width = this.$refs.shadow_body.offsetWidth
        this.styles = {
          top: obj.y + 50 + 'px',
          left: obj.x - width + 35 + 'px'
        }
      })
    }
  },
  computed: {
    ...mapState('zhishishu', ['alertState', 'history'])
  },
  methods: {
    ...mapMutations('zhishishu', ['setLoading', 'setAlertState']),
    ...mapActions('zhishishu', ['getSystemTreeList']),
    async selectTag(type, item) {
      const userId = this.$store.state.user.userInfo.id
      if (type === 0) {
        this.setLoading(true)
        const response = await this.$axios.get(
          caseBase +
            '/farbun/systemTree/use?id=' +
            item.id +
            '&userId=' +
            userId
        )
        const { code, message } = response.data
        this.setLoading(false)
        if (code === 200) {
          this.$toast.success(message)
        } else {
          this.$toast('采用失败')
        }
        // 新建文件夹 或者 重命名 调用弹框
      } else if (type === 1 || type === 2) {
        this.setAlertState({ show: true, type, item, userId })
      } else if (type === 3) {
        this.setLoading(true)
        const response = await this.$axios.post(
          `${caseBase}/farbun/myTree/delete?userId=${userId}&id=${item.id}`
        )
        const { code, message } = response.data
        if (code === 200) {
          this.$toast.success(message)
          this.getSystemTreeList(this.history[this.history.length - 1])
          this.setLoading(false)
        }
      }
    }
  },
  created() {
    const name = this.$route.name
    if (name === 'casedetail-xitongzhishi') {
      this.tag = [
        {
          title: '采用',
          icon: 'location',
          type: 0
        }
      ]
    } else if (name === 'casedetail-wodezhishi') {
      this.tag = [
        {
          title: '新建文件夹',
          icon: 'tosend',
          type: 1
        },
        {
          title: '重命名',
          icon: 'edit-data',
          type: 2
        },
        {
          title: '删除',
          icon: 'close',
          type: 3
        }
        // {
        //   title: '上传',
        //   icon: 'upgrade',
        //   type: 4
        // }
      ]
    }
  }
}
</script>

<style lang="stylus">
.shadown_container {
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  & > .shadow_body {
    position: fixed;

    &::before {
      content: '';
      border-width: 0 8px 10px;
      border-style: solid;
      border-color: transparent transparent #fff; /* 透明 透明  灰 */
      position: absolute;
      top: -6px;
      right: 5px;
    }
  }

  & .van-cell-group {
    border-radius: 5px;
    overflow: hidden;
  }
}
</style>
