<template>
  <li>
    <div data-type='0'>
      <div class="doc-title">
        <i
          class="icon"
          id="removeChild"
          style='background:url("/static/none.png") no-repeat;background-size:75%'
          @click="toggle($event)"
        ></i>
        <div class="title-content">
          <span :class="[data.level === 0 ? 'h1' : data.level === 1 ? 'h2' : data.level === 2 ? 'h3' : data.level === 3 ? 'h4' : data.level === 4 ? 'h5' : data.level === 0 ? 'h6' : '']">
            {{data.title.map(x => x.text).join('')}}
          </span>
          <b @click="block($event)"></b>
        </div>
      </div>
      <p
        class="content font-content"
        v-for="(item,index) of (data.contentEntity)"
        :key="index"
      >
        {{item.neirong}}
      </p>
      <p
        class="content"
        style='padding:0;margin:0;'
      ></p>
    </div>
    <!-- 递归组件 -->
    <ul>
      <tree-item
        v-for="(item,index) of (data.children)"
        :key="index"
        :data="item"
      ></tree-item>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'treeItem',
  props: ['data'],
  methods: {
    toggle (evt) {
      const el = evt.target.parentElement.parentElement
      const type = el.getAttribute('data-type')
      if (type === '0') {
        el.parentElement.querySelectorAll('.content').forEach(el => {
          el.style.display = 'block'
          el.parentElement.setAttribute('data-type', '1')
        })
        el.parentElement.querySelectorAll('ul').forEach(el => {
          el.style.display = 'block'
        })
        el.parentElement.querySelectorAll('.icon').forEach(el => {
          el.style.background = 'url("/static/block.png") no-repeat'
          el.style.backgroundSize = '100%'
        })
      } else {
        el.parentElement.querySelectorAll('.content').forEach(el => {
          el.style.display = 'none'
          el.parentElement.setAttribute('data-type', '0')
        })
        el.parentElement.querySelectorAll('ul').forEach(el => {
          el.style.display = 'none'
        })
        el.parentElement.querySelectorAll('.icon').forEach(el => {
          el.style.background = 'url("/static/none.png") no-repeat'
          el.style.backgroundSize = '75%'
        })
        el.parentElement.querySelectorAll('.title-content span').forEach(el => {
          el.style.cssText = '-webkit-line-clamp: 3;'
        })
      }
    },
    block (evt) {
      const el = evt.target.previousSibling
      el.style.cssText = '-webkit-line-clamp: 10;'
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  width: inherit;
}
.doc-title {
  display: flex;
  margin-bottom: 10px;
}
.doc-title > div {
  width: 500px;
}
.icon {
  cursor: pointer;
  display: inline-block;
  width: 10px;
  background-position-y: 5px !important;
}
.content {
  margin-left: 1em;
  display: none;
  /* color: #777; */
  line-height: 1.5;
}
.title-content {
  position: relative;
}
.title-content span {
  display: inline-block;
  color: #000;
  line-height: 1.5;
}
.title-content > b {
  position: absolute;
  display: inline-block;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 20px;
  cursor: pointer;
}
.title-content span:not(:first-child) {
  display: none;
}
.title-content span:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
}
.h1 {
  font-size: 18px;
  font-family: 'Microsoft YaHei' !important;
  font-weight: bold;
}
.h2 {
  font-size: 16px;
  font-family: 'Microsoft YaHei' !important;
  font-weight: bold;
}
.h3 {
  font-family: 'SimSun' !important;
  font-size: 16px;
  font-weight: bold;
}
.h4 {
  font-size: 16px;
  font-family: 'SimSun' !important;
}
.h5 {
  font-family: 'SimSun' !important;
  font-size: 12px;
}
.h6 {
  font-size: 12px;
  font-family: 'SimSun' !important;
}
.font-content {
  font-size: 12px;
  font-family: 'SimSun' !important;
  color: #000;
  text-indent: 2em;
}
</style>
