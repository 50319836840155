var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c(
      "div",
      { attrs: { "data-type": "0" } },
      [
        _c("div", { staticClass: "doc-title" }, [
          _c("i", {
            staticClass: "icon",
            staticStyle: {
              background: 'url("/static/none.png") no-repeat',
              "background-size": "75%",
            },
            attrs: { id: "removeChild" },
            on: {
              click: function ($event) {
                return _vm.toggle($event)
              },
            },
          }),
          _c("div", { staticClass: "title-content" }, [
            _c(
              "span",
              {
                class: [
                  _vm.data.level === 0
                    ? "h1"
                    : _vm.data.level === 1
                    ? "h2"
                    : _vm.data.level === 2
                    ? "h3"
                    : _vm.data.level === 3
                    ? "h4"
                    : _vm.data.level === 4
                    ? "h5"
                    : _vm.data.level === 0
                    ? "h6"
                    : "",
                ],
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.data.title.map((x) => x.text).join("")) + " "
                ),
              ]
            ),
            _c("b", {
              on: {
                click: function ($event) {
                  return _vm.block($event)
                },
              },
            }),
          ]),
        ]),
        _vm._l(_vm.data.contentEntity, function (item, index) {
          return _c("p", { key: index, staticClass: "content font-content" }, [
            _vm._v(" " + _vm._s(item.neirong) + " "),
          ])
        }),
        _c("p", {
          staticClass: "content",
          staticStyle: { padding: "0", margin: "0" },
        }),
      ],
      2
    ),
    _c(
      "ul",
      _vm._l(_vm.data.children, function (item, index) {
        return _c("tree-item", { key: index, attrs: { data: item } })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }