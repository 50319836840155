var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.docTree, function (item, index) {
      return _c(
        "ul",
        { key: index, staticClass: "tree-container" },
        [_c("doc-tree-children", { attrs: { data: item } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }