var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("search"),
      _c("breadcrumb"),
      _c("content-view", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showTree,
            expression: "!showTree",
          },
        ],
      }),
      _c("global-alert"),
      _c("doc-tree", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTree,
            expression: "showTree",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }