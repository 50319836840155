<template>
  <div class="search_container" v-show="isSearch">
    <van-search
      v-model="value"
      placeholder="请输入搜索关键词"
      show-action
      id="search_input"
      @search="onSearch"
    >
      <div slot="action" @click="onSearch">搜索</div>
    </van-search>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { caseBase } from '~api-config'

export default {
  data() {
    return {
      value: ''
    }
  },
  computed: {
    ...mapState('zhishishu', ['isSearch', 'tabNum'])
  },
  methods: {
    ...mapMutations('zhishishu', ['setAnjiandangan']),
    ...mapActions('zhishishu', ['getSearchData']),
    async onSearch() {
      // if (this.value) {
      // 判断是否 是我的知识 和 系统知识
      // if (this.tabNum === 3 || this.tabNum === 2) {
      //   this.getSearchData({ name: this.value })
      //   this.value = ''
      // }
      // 案件档案
      // if (this.tabNum === 1) {
      const userId = this.$store.state.user.userInfo.id
      const response = await this.$axios.get(
        `${caseBase}/new-design/fileManage/like?userId=${userId}&fileName=${this.value}`
      )
      const { fileModels } = response.data.data
      const data = fileModels.map(item => {
        return {
          name: item.fileName,
          types: 3,
          ...item
        }
      })
      this.setAnjiandangan(data)
      // }
      // }
    }
  }
}
</script>

<style lang="stylus">
.search_container
  & .van-cell__value--alone
    display flex
    align-items center
  & .van-field__body
    width 100%
</style>
