<template>
  <div class="options_alert">
    <van-dialog
      v-model="show"
      show-cancel-button
      :before-close="beforeClose"
    >
      <p>
        {{
        (alertState.type === 1 && '新建文件夹')
        ||
        (alertState.type === 2&& '重命名')
        }}
      </p>
      <van-field
        v-model="username"
        placeholder="请输入..."
      />
    </van-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { caseBase } from '~api-config'
export default {
  data () {
    return {
      username: ''
    }
  },
  computed: {
    ...mapState('zhishishu', ['alertState', 'history']),
    show: {
      get () {
        return this.alertState.show
      },
      set (value) {
        this.setAlertState({ ...this.alertState, show: value })
      }
    }
  },
  watch: {
    alertState (value) {
      if (value.type === 2) {
        this.username = value.item.text
      } else {
        this.username = ''
      }
    }
  },
  methods: {
    ...mapActions('zhishishu', ['getSystemTreeList']),
    ...mapMutations('zhishishu', ['setAlertState']),
    async beforeClose (action, done) {
      if (action === 'confirm') {
        // 新建文件夹
        if (this.alertState.type === 1 && !this.isNull(this.username)) {
          const response = await this.$axios.post(`${caseBase}/farbun/myTree/addSubdirectory?pId=${this.alertState.item.pId}&userId=${this.alertState.userId}&text=${this.username}&file=false`)
          const { code, message } = response.data
          if (code === 200) {
            this.$toast.success(message)
            this.getSystemTreeList(this.history[this.history.length - 1])
            done()
          }
        } else if (this.alertState.type === 2 && !this.isNull(this.username)) {
          const response = await this.$axios.put(`${caseBase}/farbun/myTree/rename?id=${this.alertState.item.id}&userId=${this.alertState.userId}&text=${this.username}`)
          const { code, message } = response.data
          if (code === 200) {
            this.$toast.success(message)
            this.getSystemTreeList(this.history[this.history.length - 1])
            done()
          }
        } else {
          this.$toast.fail('无效字符')
          done()
        }
      } else {
        done()
      }
    },
    isNull (str) {
      if (str === '') {
        return true
      }
      var regu = '^[ ]+$'
      var re = new RegExp(regu)
      return re.test(str)
    }
  }
}
</script>

<style lang="stylus">
.options_alert {
  & p {
    text-align: center;
    padding: 10px;
  }
}
</style>
