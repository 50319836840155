<template>
  <div>
    <search />
    <breadcrumb />
    <content-view v-show="!showTree" />
    <global-alert />
    <doc-tree v-show="showTree" />
  </div>
</template>

<script>
import Search from '../../components/Search'
import Breadcrumb from '../../components/Breadcrumb'
import ContentView from '../../components/content/ContentCell'
import GlobalAlert from '../../components/content/alert'
import DocTree from '../../components/docTree'
import { caseBase } from '~api-config'
import { mapMutations, mapState, mapActions } from 'vuex'
export default {
  components: {
    Search,
    Breadcrumb,
    ContentView,
    GlobalAlert,
    DocTree
  },
  computed: {
    ...mapState('zhishishu', ['history', 'showTree'])
  },
  methods: {
    ...mapMutations('zhishishu', ['setContentData', 'setHistory', 'setLoading', 'setUserId', 'setShowTree']),
    ...mapActions('zhishishu', ['getSearchData'])
  },
  async created () {
    // 有默认值
    const { caseId } = this.$route.params
    if (caseId !== 'ios&android') {
      this.setShowTree(false)
      this.setUserId(this.$store.state.user.userInfo.id)
      if (this.history.length === 0) {
        const data = { id: 0, active: true, name: '首页' }
        this.setHistory({ data: data })
      }
      this.setLoading(true)
      const response = await this.$axios.get(`${caseBase}/new-design/modals/getCaseName?caseId=${caseId}`)
      const { causeName } = response.data.data
      await this.getSearchData({ name: causeName })
      this.setLoading(false)
    } else {
      this.setShowTree(false)
      this.setUserId(this.$store.state.user.userInfo.id)
      if (this.history.length === 0) {
        const data = { id: 0, active: true, name: '首页' }
        this.setHistory({ data: data })
      }
      this.setLoading(true)
      const response = await this.$axios.get(caseBase + '/farbun/systemTree/top')
      const { systemTree } = response.data.data
      const result = systemTree.map(item => {
        item.renderType = {
          leftIcon: 'shop',
          rightIcon: false
        }
        return item
      })
      this.setContentData(result)
      this.setLoading(false)
    }
  },
  destroyed () {
    this.setHistory({ data: { id: 0, active: true, name: '首页', types: 0 }, type: true })
  }
}
</script>
