<template>
  <div>
    <ul
      class="tree-container"
      v-for="(item,index) of docTree"
      :key="index"
    >
      <doc-tree-children :data="item"></doc-tree-children>
    </ul>
  </div>
</template>

<script>
import docTreeChildren from './docTreeChildren.vue'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('zhishishu', ['docTree'])
  },
  components: {
    docTreeChildren
  }
}
</script>

<style scoped>
.tree-container {
  padding: 0 15px;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.flod {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
</style>
