var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isSearch,
          expression: "isSearch",
        },
      ],
      staticClass: "search_container",
    },
    [
      _c(
        "van-search",
        {
          attrs: {
            placeholder: "请输入搜索关键词",
            "show-action": "",
            id: "search_input",
          },
          on: { search: _vm.onSearch },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        [
          _c(
            "div",
            {
              attrs: { slot: "action" },
              on: { click: _vm.onSearch },
              slot: "action",
            },
            [_vm._v("搜索")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }