<template>
  <div :class="isSearch ? 'cell_container_search' : 'cell_container'">
    <van-cell-group>
      <van-cell v-for="(item,index) in contentData"
                :key="index"
                :icon="item.renderType.leftIcon"
                :title="item.text"
                @click="selectCell(item);getSystemTreeList(item)">
        <van-icon slot="right-icon"
                  :name="item.renderType.rightIcon ? 'edit' : ''"
                  @click.stop="showShadown($event,item)"
                  class="van-cell__right-icon" />
      </van-cell>
    </van-cell-group>
    <option-component :position="position"
                      :result="result" />
  </div>
</template>

<script>
import OptionComponent from './option'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  components: {
    OptionComponent
  },
  data () {
    return {
      position: {},
      result: {}
    }
  },
  computed: {
    ...mapState('zhishishu', ['isSearch', 'historyId', 'contentData'])
  },
  methods: {
    ...mapMutations('zhishishu', ['setHistory']),
    ...mapActions('zhishishu', ['getSystemTreeList']),
    showShadown (event, item) {
      const x = event.clientX - event.offsetX
      const y = event.clientY - event.offsetY
      this.position = { x, y }
      this.result = item
    },
    // 点击添加面包屑
    selectCell ({ id, text }) {
      const obj = {
        id,
        name: '>' + text,
        active: true
      }
      this.setHistory({ data: obj })
    }
  }
}
</script>

<style lang="stylus">
.cell_container
  height calc(100vh - 176px + 44px)
  overflow hidden auto
  padding-bottom 300px
  & .van-cell__title, .van-cell__value
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
.cell_container_search
  height calc(100vh - 176px)
  overflow hidden auto
  padding-bottom 300px
  & .van-cell__title, .van-cell__value
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
</style>
